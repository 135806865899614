@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #0E0E0E;
}
.btn-primary {
    @apply w-full p-2 bg-[#d61619] text-white text-center text-xl rounded-md mt-4;
}

.msd__button--confirm {
    background-color: #D61619!important;
    border-radius: 6px!important;
}


.msd__button--secondary {
    border-radius: 6px!important;
}

.msd__button--secondary:hover {
    background-color: #FFF!important;
    color: #181818!important;
}

.msd__header {
    display: none!important;
}

.msd__capture > div {
    padding: 0!important;
}

.msd__auth-wrapper {
    background: none!important;
}

.msd__auth-content {
    width: 100%!important;
}

.msd__footer {
    display: none!important;
}

.msd__input__wrapper {
    background-color: #FFF!important;
    border-color: transparent!important;
    border-radius: .5rem;
}

.msd__input__wrapper:focus-within {
    border-color: transparent!important;
}

input + label:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='size-6'%3E%3Cpath fill-rule='evenodd' d='M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    opacity: .4;
}

input[type=email] + label:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23565656' class='size-6'%3E%3Cpath d='M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z' /%3E%3Cpath d='M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z' /%3E%3C/svg%3E%0A");
}

input[type=password] + label:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23565656' class='size-6'%3E%3Cpath fill-rule='evenodd' d='M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
}

.msd__input__wrapper .msd__input {
    color: #000!important;
}

.msd__input__wrapper .msd__input__label {
    color: #000;
    inset-block-start: .75rem;
    transform:none;
    padding-left: 45px
}

.msd__input__wrapper:focus-within .msd__input__label {
    transform: none;
    display: none;;
}

.msd__input:not([value=""]) + .msd__input__label {
    display: none;
    transform: none;
} 

.react-select__control {
    border-radius: 6px!important;
    border-color: #FFF!important;
}

.react-select__control--is-focused:focus-within, .react-select__control--is-focused:focus {
    border-color: #FFF;
    box-shadow: none;
}

.react-select__placeholder {
    color: #333!important;
}

.msd__select__wrapper .react-select__control .react-select__value-container {
    padding: 7px;
}

.msd__select__wrapper .react-select__control .react-select__value-container .react-select__placeholder:before,
.msd__select__wrapper .react-select__control .react-select__value-container--has-value .react-select__single-value:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='size-6'%3E%3Cpath fill-rule='evenodd' d='M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    margin-top: -2px;
    left: 15px;
    opacity: .4;

}

.msd__select__wrapper .react-select__control .react-select__value-container .react-select__placeholder,
.msd__select__wrapper .react-select__control .react-select__value-container--has-value .react-select__single-value {
    text-align: left;
    padding-left: 45px;
}

.msd__capture {
    padding-top: 15px;
}

.msd__capture > div  > div {
    display: none;
}

/*
 * Checkout section
 */
.msd__checkout-wrapper {
    padding: 0!important;
    border-bottom: none!important;
}

.msd__checkout-wrapper .msd__section-header {
    display: none;
}

.msd__checkout-wrapper .msd__offer-card {
    padding: 1.5rem;
    border: none;
    background: #181818;
}

.msd__checkout-wrapper .msd__offer-card > section {
    flex-flow: column;
}

.msd__checkout-wrapper .msd__offer-card .msd__checkout-card-price__badge {
    background-color: rgb(214, 22, 25);
    border: none;
}

.msd__checkout-wrapper .msd__offer-card .msd__subscription-card__icon {
    display: none;
}

.msd__checkout-wrapper .msd__price-summary {
    display: none;
}

.msd__payment {
    border-bottom: none!important;
    padding: 0!important;
    background-color: transparent!important;
}

/*
 * Redeem code styles
 */
.msd__checkout-wrapper .msd__coupon-input__wrapper--inner {
    background: none;
    border-radius: 0;
    border: none;
    display: block;
    outline: none;
}

.msd__checkout-wrapper .msd__coupon-input__wrapper--inner .msd__coupon-input__close {
    top: 9px;
    right: 15px;
    left: auto; 
    transform: none;
    z-index: 2;
}

.msd__checkout-wrapper .msd__coupon-input__wrapper--inner .msd__coupon-input__input {
    width: 100%;
    max-width: 100%;
    inset-inline-start: auto;
    height: 40px;
    margin-bottom: 15px;
    border-radius: 6px;
    padding:5px 8px;
}

.msd__checkout-wrapper .msd__coupon-input__wrapper--inner .msd__button--primary {
    width: 100%;
    border-radius: 6px;
    font-size: 16px;
}

.msd__checkout-wrapper .msd__coupon-input__wrapper--inner .msd__button--primary:focus {
    outline: none;
}

.msd__checkout-wrapper .msd__coupon-input__wrapper {
    max-width: 100%;
}

.msd__checkout-wrapper .msd__offer-card + div {
    width: 100%;
}

/*
 * Adyen styles
 */
.msd__payment .msd__section-header {
    font-size: 14px;
}

.msd__payment .msd__payment__adyen, .msd__payment .msd__payment__wrapper {
    max-width: 100%;
    margin: auto;
}

.msd__payment .msd__payment__adyen .adyen-checkout__payment-method.adyen-checkout__payment-method--selected {
    background-color: transparent;
    border-color: transparent;
}

.msd__payment .msd__payment__adyen .adyen-checkout__payment-method__details {
    padding: 0;
}

.msd__payment .msd__payment__adyen .checkbox-wrapper {
    margin: 10px 0 20px 0;
}

.msd__payment .msd__payment__adyen .adyen-checkout__payment-method__header {
    padding: 13px 0!important;
}

.msd__payment .msd__payment__adyen .adyen-checkout__payment-method__name {
    color: #FFF!important;
    font-weight: normal;
}

.msd__payment .msd__payment__adyen .msd__consents__text {
    color: #FFF;
}

.msd__payment .msd__payment__adyen .adyen-checkout__button {
    background: #D61619!important;
}

.msd__payment .msd__payment__adyen .adyen-checkout__input {
    color: #181818!important;
}

.msd__payment .msd__payment__adyen .adyen-checkout__input--focus,
.msd__payment .msd__payment__adyen .adyen-checkout__input--focus:hover, 
.msd__payment .msd__payment__adyen .adyen-checkout__input:active, 
.msd__payment .msd__payment__adyen .adyen-checkout__input:active:hover,
.msd__payment .msd__payment__adyen .adyen-checkout__input:focus, 
.msd__payment .msd__payment__adyen .adyen-checkout__input:focus:hover {
    border: 1px solid #FFF;
    box-shadow: none;
    color: #181818!important;
}

@media (max-width: 767px) {
    .container {
        max-width: 100%;
        padding: 0 1rem;
    }
}
